import axios from "axios";
import store from "./store/index";
import router from "./router/index";

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_APP_URL + "/api/v1/admin",
});

apiClient.interceptors.response.use(undefined, function(err) {
  return new Promise(function() {
    if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
      store.dispatch("logout").then(() => {
        router.push("/").catch((err) => {
          console.log(err);
        });
      });
    }
    throw err;
  });
});

export default apiClient;
